import { RefresherEventDetail } from '@ionic/core'
import { Mutator } from "apprise-frontend-core/state/api"
import { bridge } from "../../bridge"
import produce, {current} from "immer"
import { useBusyState } from "lib/apprise-frontend-core/utils/busyguard"
import { useProcess } from 'process/api'
import { useContext } from "react"
import { User } from "user/model"
import Worker from 'worker'
import { usePirCall } from "../calls"
import { Pir, PirEdting, PirExtended, PirInspectionErrors, PirInspectionSignatures, PirInspectionStatus, statuses, ValidateStep } from "../model"
import { convertExtendedInternalToRemote, convertExtendedRemoteToUpload, dayAfterArchivation, mergeRemoteToPir, pirmodelapi, pirsModelApi } from "../model/api"
import { containsErrors } from "../model/inspectionErrorApi"
import { usePirPreparationProcess } from "../processes/preparation"
import { useSubmitProcess } from '../processes/submit'
import { PirContext } from "../provider"
import { initialPirs, PirState, PirStateObject } from "../state"
import { usePirsFilesAndPictures } from './fileAndPictures'
import { useUser } from 'user/api'
import { toastApi } from 'toast/api'
import { useIntl } from 'lib/apprise-frontend-core/intl/api'


const instance = new Worker();

// a hook that takes the state from a context and makes the api available to components.

export const usePirs = () => {

    const state = useContext(PirContext)
    const busy = useBusyState()
    const call = usePirCall()
    const process = useProcess()
    const submitProcessApi = useSubmitProcess()
    const user = useUser()
    const {t} = useIntl()
    const createPreparationProcess = usePirPreparationProcess()

    const stateset = async (_:Mutator<PirStateObject>) => {
        state.set(_)
        instance.pir.put(state.get().pirstate)
    }

    const self = {

        fetchAll: async (event?: CustomEvent<RefresherEventDetail>, refreshOnly?) => {
            const data = await instance.pir.get()
            if(data){
                !refreshOnly && self.setState(data)

                if(!navigator.onLine){
                    event && event.detail.complete()
                    return 
                }

                return call.fetchAll().then(self.mergeFromRemote)
                .catch(()=>console.log('can\'t get pirs'))
                .finally(()=>event && event.detail.complete())
            } else {
                return busy.toggle('main', t('loading.fetchingPirs'))
                .then(call.fetchAll)
                .then(self.mergeFromRemote)
                .catch(()=>console.log('can\'t get pirs'))
                .finally(()=>{
                    event && event.detail.complete()
                    busy.toggle('main');
                })
            }
        }
        ,
        claim: async (pir:Pir, user:User) => {
            let statusSetUp = false
            let processSetUp = false
            try {
                await busy.toggle('claim', t('loading.claiming'))
                await call.claim(pir, user)
                
                let newPir:Pir= pirmodelapi({
                        ...pir,
                        claimedByDevice:user.deviceName,
                    }).updateStatus(statuses.preparing)
                

                await self.set(newPir)
                
                statusSetUp = true
                
                await process
                    .add(createPreparationProcess(newPir))
                
                processSetUp = true
                
                await busy.toggle('claim')
            } catch (error) {
                if(!statusSetUp || !processSetUp){
                    self.set(pir)
                }
                busy.toggle('claim')
                bridge.renderError && bridge.renderError(error as Error)
            }
        }

        ,

        startInspection: async (pir:Pir) => {
            const newPir = pirmodelapi(pir).updateStatus(statuses.inspecting)
            await self.set(produce(newPir, p=>{
                if(p.extended){
                    p.extended.pir.startOfInspection = (p.lifecycle && p.lifecycle[statuses.inspecting] &&  new Date(p.lifecycle[statuses.inspecting].date).getTime()) || new Date().getTime()
                }
                console.log(current(p))
            }))
        }

        ,

        endInspection: async (pir:Pir) => {
            const  newPir = pirmodelapi({...pir}).updateStatus(statuses.readytosubmit)
            await self.set(produce(newPir, p=>{
                if(p.extended){
                    p.extended.pir.endOfInspection = (p.lifecycle && p.lifecycle[statuses.readytosubmit] &&  new Date(p.lifecycle[statuses.readytosubmit].date).getTime()) || new Date().getTime()
                }
                console.log(current(p))
            }))
        }

        ,

        release: async (pir:Pir, user:User) => {
            try {
                await busy.toggle('release', t('loading.releasing'))
                await call.release(pir, user)
                const newPir = pirmodelapi({
                    ...pir,
                    claimedByDevice:null,
                    lifecycle: undefined
                }).updateStatus(statuses.notready)
                await self.set(newPir)
                await busy.toggle('release')
            } catch (error) {
                bridge.renderError && bridge.renderError(error as Error)
            }
        }

        ,

        submit: async (pir:PirEdting, user:User) => {
            let statusSetUp = false
            let processSetUp = false
            try {
                await busy.toggle('submit', t('loading.submittingPir'))
                const extendedUpload = await self.prepareForSubmission(pir)
                console.log('submit json:')
                console.log(extendedUpload)
            
                await call.startSubmit(pir, extendedUpload, user)
                
                const newPir = pirmodelapi({
                    ...pir,
                }).updateStatus(statuses.submitting)

                await self.set(newPir)

                statusSetUp = true

                const submitProcess = await submitProcessApi.create(pir, extendedUpload, state)
                await process
                    .add(submitProcess)

                processSetUp = true

                await busy.toggle('submit')
            } catch (error) {
                if(!statusSetUp || !processSetUp){
                    self.set(pir)
                }
                console.error(error)
                await busy.toggle('submit')
                bridge.renderError && bridge.renderError(error as Error)
            }
        }

        ,

        archive: async (pir:Pir) => {
            await self.delete(pir)
            toastApi().create({
                dismissable:true,
                duration: 0,
                color:"danger",
                message:t('toast.pirArchived', {vessel:pirmodelapi(pir).title(),})})
        }

        ,

        prepareForSubmission: async (pir:PirEdting) => {
            const remote = convertExtendedInternalToRemote(pir.extended)
            const upload = convertExtendedRemoteToUpload(remote, pir.inspectionSignatures || {} as PirInspectionSignatures)
            //retrieve all the picture from the extended pir file
            //add the pictures into the state and the indexed db

            //put user inspector name into monitoring details
            // const _upload = {...upload}
            // const inspectorName = user.current()?.inspector.name
            // const monitoringDetailsInspectorName = upload.pir.monitoringDetails?.principalInspectorName
            // if(inspectorName && _upload.pir.monitoringDetails && (inspectorName !== monitoringDetailsInspectorName)){
            //     _upload.pir.monitoringDetails.principalInspectorName = inspectorName
            // }
            //return the pir extended for upload
            return upload
        }

        ,

        setAsReady: async (pir:Pir) => {
            const newPir = pirmodelapi(pir).updateStatus(statuses.ready)
            await self.set(newPir)
        }
        
        ,

        setAsSubmitted: async (pir:Pir) => {
            const newPir = pirmodelapi(pir).updateStatus(statuses.submitted)
            await self.set(newPir)
        }

        ,
        
        lastOpenid: () => state.get().pirstate.lastOpenId
        
        ,
        
        all: () => state.get().pirstate.pirs
        
        ,
        
        find: (id:string|number) => pirsModelApi(self.all()).find(id)?.pir
        
        ,
        
        ready: () => pirsModelApi(self.all()).ready().pirs
        
        ,
        
        preparing: () => pirsModelApi(self.all()).preparing().pirs
        
        ,
        
        inspecting: () => pirsModelApi(self.all()).inspecting().pirs
        
        ,

        signing: () => pirsModelApi(self.all()).signing().pirs
        
        ,

        submitting: () => pirsModelApi(self.all()).submitting().pirs
        
        ,

        submitted: () => pirsModelApi(self.all()).submitted().pirs

        ,
        
        notready: (pirs?:Pir[]) => pirsModelApi(pirs!==undefined ? pirs : self.all()).notready().pirs.filter(p=>p.claimedByDevice === null)
        
        ,

        claimedByOthers: (pirs?:Pir[]) => pirsModelApi(pirs!==undefined ? pirs : self.all()).notready().pirs.filter(p=>p.claimedByDevice && (p.claimedByDevice !== user.current()?.deviceName))

        ,
        
        setState: (pirstate: PirState) => stateset(s => s.pirstate = pirstate)
        
        ,
        
        setAll: (all: Pir[]) => stateset(s => s.pirstate.pirs = all)

        ,

        ports: () => pirsModelApi(self.all()).ports()

        ,

        mergeFromRemote: (all:Pir[]) => {
            stateset(s => {
                //check if there are pirs deleted remotely
                const allID = all.map(a=>pirmodelapi(a).id())
                const onlyInternalPirs = s.pirstate.pirs.filter(p=>!allID.includes(pirmodelapi(p).id()))
                
                let toDelete:Pir[] = []
                let toArchive:Pir[] = []
                let others:Pir[] = []
                onlyInternalPirs.forEach(p=>{
                    if(pirmodelapi(p).hasStatusLessThan(statuses.inspecting))
                        toDelete.push(p)
                    // this is removed until we find the bug
                    // else if(pirmodelapi(p).isToArchive())
                    //     toArchive.push(p)
                    else
                        others.push(p)
                })

                toDelete.forEach(d=>{
                    toastApi().create({
                        duration: 0,
                        dismissable:true,
                        color:"danger",
                        message:t('toast.pirDeleted', {vessel:pirmodelapi(d).title()})})
                })

                toArchive.forEach(d=>{
                    toastApi().create({
                        duration: 0,
                        dismissable:true,
                        color:"danger",
                        message:t('toast.pirArchivedAfterPeriod', {vessel:pirmodelapi(d).title(), days:dayAfterArchivation})})
                })


                //merge remote with currents
                s.pirstate.pirs = all.map(p=>{
                    const id = pirmodelapi(p).id()
                    const currentPir = s.pirstate.pirs && s.pirstate.pirs.find(currentPir=>pirmodelapi(currentPir).hasId(id))
                    if(currentPir){
                        let mergedPir = mergeRemoteToPir(p, currentPir)
                        if(p.claimedByDevice !== null && p.claimedByDevice !== user.current()!.deviceName && currentPir.status !== statuses.notready){
                            console.log('claimed by another')
                            mergedPir = pirmodelapi(mergedPir).updateStatus(statuses.notready)
                            toastApi().create({
                                duration: 0,
                                dismissable:true,
                                color:"warning",
                                message:t('toast.pirClaimedByOther', {vessel:pirmodelapi(mergedPir).title()})})
                        }
                        return mergedPir
                    }
                    return p
                })

                //concat orhers
                s.pirstate.pirs = [...s.pirstate.pirs, ...others]
            })
        }
        
        ,
        
        set: async (pir: Pir) => {
            const id = pirmodelapi(pir).id()
            await stateset(s => s.pirstate.pirs = s.pirstate.pirs.map(p=>
                pirmodelapi(p).hasId(id) ? pir : p
            ))
        }
        
        ,
        
        setUsing: (pir:PirEdting) => async (mutator:Mutator<PirEdting>) => {
            await self.set(produce(pir, t => void (mutator(t))))
        }
        
        ,
        
        setStep: (key, pir:PirEdting, validate?: ValidateStep) => (m:Mutator<PirEdting>) => {
            if(pirmodelapi(pir).hasStatusMoreThan(statuses.inspecting)){
                return
            }
            const isComplete = pir.inspectionstatus && pir.inspectionstatus[key]
            self.set(produce(pir, t => {

                void (m(t))
                if(isComplete){
                    (t.inspectionstatus as PirInspectionStatus)[key]=false
                }
                if(validate !== undefined){
                    const errors = validate(t)
                    if(!t.inspectionErrors){
                        t.inspectionErrors = {} as PirInspectionErrors
                    }
                    if(!(t.inspectionErrors as PirInspectionErrors)[key]){
                        (t.inspectionErrors as PirInspectionErrors)[key] = {errors:{}, isValid: false}
                    }
                    (t.inspectionErrors as PirInspectionErrors)[key].errors=errors;
                    (t.inspectionErrors as PirInspectionErrors)[key].isValid=!containsErrors(errors)
                }
            }))            
        }
        
        ,
        
        setLastOpenId: (id:string) => stateset(s => s.pirstate.lastOpenId = id)
        
        ,
        
        add: (p:Pir) => stateset(s => s.pirstate.pirs.push(p))
        
        ,
        
        delete: (pir:Pir) => stateset(s => s.pirstate.pirs = s.pirstate.pirs.filter(p=>pirmodelapi(pir).id() !== pirmodelapi(p).id()))
        
        ,
        
        reset: () => stateset(s => s.pirstate = initialPirs.pirstate)
        
        ,
        
        isInitialized: () => state.get().pirstate.isInitialized ? true : false
        
        ,

        init: async ()=> {
            await self.fetchAll()
            await stateset(s=>s.pirstate.isInitialized = true)
        }

        ,

        export: async (only?:Pir[]) => {
            const pirState = await instance.pir.get()
            if(!pirState)
                return {}
            return {
                ...pirState,
                pirs: only ? pirState.pirs.filter(p=>only.map(o=>pirmodelapi(o).id()).includes(pirmodelapi(p).id())) : pirState.pirs
            }
        }

        ,

        restore: async (pirs:PirState) => {
            await self.setState(pirs)
        }
        
        ,

        ...usePirsFilesAndPictures()
    }

    return self

}