import { Mutator } from "apprise-frontend-core/state/api"
import { Pir } from "pir/model"
import { pirmodelapi } from "pir/model/api"
import { useContext } from "react"
import Worker from 'worker'
import { Process, ProcessHandlers, Task, TaskHandlers, taskStatuses } from "./model"
import { processModelApi } from "./model/api"
import { useResolveProcess } from "./model/processes"
import { useRegisterTask, useResolveTask } from "./model/tasks"
import { ProcesContext, ProcessRegistryContext } from "./provider"
import { initialProces, ProcesState } from "./state"

const instance = new Worker();

export const useProcessRegistry = () => {
    const state = useContext(ProcessRegistryContext)

    const self = {
        registerProcess: async (p:ProcessHandlers) => {
            await state.setQuietly(_=>_.processes = {...self.processes(), ...p})
        },

        registerTask: async (t:TaskHandlers) => {
            await state.setQuietly(_=>_.tasks = {...self.tasks(), ...t})
        },

        processes: () => state.get().processes,

        tasks: () => state.get().tasks

    }
    return self
}

export const useBaseProcess = () => {
    const state = useContext(ProcesContext)

    const stateset = async (_:Mutator<ProcesState>) => {
        await state.set(_)
        await instance.process.put(state.get().processes)
    }

    const statesetQuietly = async (_:Mutator<ProcesState>) => {
        await state.setQuietly(_)
        await instance.process.put(state.get().processes)
    }

    const self = {
        fetchAll: async () => {
            const processes = await instance.process.get()
            if(processes){
                self.setAll(processes);
            }
        }
        ,
        all: () => state.get().processes
        ,
        getPirProcesses: (pir:Pir) =>self.all().find(p=>p.subject === pirmodelapi(pir).id())
        ,
        setAll: (all: Process[]) => stateset(s => s.processes = all)
        ,
        setAllQuietly: (all: Process[]) => statesetQuietly(s => s.processes = all)
        ,
        reset: () => stateset(s=>s.processes = initialProces().processes)
        ,
        add: async (newProcess:Process) => {
            await stateset(s=>s.processes.push(newProcess));
        },
        delete: async (process:Process) => {
            await stateset(s=>s.processes = s.processes.filter(p=>p.uid !== process.uid))
        },
        stateset,
        statesetQuietly,
        export: () => instance.process.get(),
        restore: (process:Process[]) => stateset(s => s.processes = process)
    }
    return self
}

export const dbProcessApi = () => {
    const self = {
        put: (processes:Process[]) => instance.process.put(processes),
        get: () => instance.process.get()
    }
    return self
}

export const useDBProcess = () => {
    return dbProcessApi()
}
export const useProcess = () => {

    const registerTaskApi = useRegisterTask()
    const resolveTaskApi = useResolveTask()
    const resolveProcessApi = useResolveProcess()
    const process = useBaseProcess()
    const db = useDBProcess()

    const self = {

        ...process,

        db,
        
        setTasks: async (process:Process, tasks:Task[]) => {
            let processes = await instance.process.get()
            if(!processes) return

            let found = false
            const pIndex = processes.findIndex(p=>p.uid===process.uid)
            let tIndex = 0
            if (pIndex !== -1){
                for (let task of tasks){
                    tIndex = processes[pIndex].tasks.findIndex((t)=>t.uid===task.uid)
                    if (tIndex !== -1){
                        found = true
                        processes[pIndex].tasks[tIndex] = task
                    }   
                }
            }
            if(found){
                await instance.process.put(processes)
            }
            
        }
        ,
        checkTasks: async () => {
            await self.registerTasks()
            await self.resolveTasks()
            await self.checkStoppedTask()
            await self.resolveProcesses()
        }
        ,
        retryTasks: async (process:Process, _tasks?:Task[]) => {
            const tasks = _tasks || processModelApi(process).getRegectedTasks()
            if(tasks.length === 0){
                return 
            }
            
            await self.setTasks(process, tasks.map(t=>({...t, status:taskStatuses.UNDEFINED})))
        }
        ,
        ignoreIgnorableTasks: async (process:Process, _tasks?:Task[]) => {
            const tasks = _tasks || processModelApi(process).getIgnorableTasks()
            if(tasks.length === 0){
                return 
            }
            
            await self.setTasks(process, tasks.map(t=>({...t, status:taskStatuses.IGNORED})))
        }
        ,
        registerTasks: async () => {
            const processes = await instance.process.get() //from the DATABASE
            if(!processes) return

            const tasksToRegister = processes.reduce((o, p)=>[...o, ...processModelApi(p).getTasksToRegister()], [] as Task[])
            if(tasksToRegister.length===0) return

            tasksToRegister.forEach(t=>t.status=taskStatuses.REGISTERED)
            await instance.process.put(processes) 

            await Promise.all(tasksToRegister.map(async (task)=>{
                const ImAlive = await setInterval(async () => {
                    task.iamAlive =  (task.iamAlive!==undefined && task.iamAlive>=0) ? task.iamAlive+1 : 0
                    await instance.process.put(processes)
                }, 300);
                await registerTaskApi.register({task, processes})
                await clearInterval(ImAlive)
                await instance.process.put(processes)
            }))
        },
        resolveTasks: async () => {
            const processes = await instance.process.get() //from the DATABASE
            if(!processes) return

            let toresolve:{process:Process, task:Task}[] = []

            processes.forEach((p, pi)=>{
                p.tasks.forEach((t, ti)=>{
                    if(t.status === taskStatuses.COMPLETED){
                        toresolve.push({process:p, task:t})
                    }
                })
            })

            if(toresolve.length===0) return

            await Promise.all(toresolve.map(async ({process,task})=>{
                await resolveTaskApi.resolve({task}) 
                await instance.process.put(processes)
            }))

        },
        checkStoppedTask: async () => {
            const processes = await instance.process.get()
            if(!processes) return

            let rejected = 0

            processes.forEach((p, pi)=>{
                p.tasks.forEach((t, ti)=>{
                    if(t.status === taskStatuses.REGISTERED){
                        if(t.iamAlive!==undefined && t.iamAlive>0 && t.iamAlive === self.all()[pi].tasks[ti].iamAlive){
                            t.status = taskStatuses.REJECTED
                            t.result = new Error('stopped')
                            rejected++
                        }
                    }
                })
            })

            if(rejected>0) await instance.process.put(processes)
        },
        resolveProcesses: async () => {
            const processes = await instance.process.get() //from the DATABASE
            if(!processes) return

            await Promise.all(
                processes
                .filter(p=>processModelApi(p).isCompleted() ? true : false)
                .map(async process=>{
                    await resolveProcessApi.resolve({process})
                })
            )
        }
        ,
        syncStateWithDB: async () => {
            const processes = await instance.process.get()
            if(processes && processes.length > 0){
                self.setAll(processes);
            }  
        } 
    }

    return self

}